<template>
  <div class="dashboard">
    <dashboard-info-block />
    <!-- <dashboard-charts /> -->
    <!--<div class="row row-equal">
      <div class="flex xs12 lg6">
        <dashboard-items />
      </div>
      <div class="flex xs12 lg6">
        <dashboard-categories />
      </div>
    </div> -->
  </div>
</template>

<script>
import DashboardInfoBlock from "./DashboardInfoBlock";
// import DashboardCharts from "./DashboardCharts";
// import DashboardItems from "./DashboardItems";
// import DashboardCategories from "./DashboardCategories";

export default {
  name: "dashboard",
  components: {
    DashboardInfoBlock,
    // DashboardCharts
    // DashboardCategories,
    // DashboardItems,
  },
  methods: {
    addAddressToMap({ city, country }) {
      this.$refs.dashboardMap.addAddress({ city: city.text, country });
    }
  }
};
</script>

<style lang="scss">
.row-equal .flex {
  .va-card {
    height: 100%;
  }
}

.dashboard {
  .va-card {
    margin-bottom: 0 !important;
    &__title {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
