<template>
  <div class="row row-equal">
    <div class="flex xl12 xs12">
      <div class="row">
        <h5 class="display-5 flex xs12 xl12 pb-0">Statistic</h5>
      </div>
      <div class="row">
        <div class="flex xs12 md8 date-wrapper">
          <span class="float-left">Choose date from :&nbsp;</span>
          <span class="float-left"><datepicker v-model="startDate" /></span>
          <span class="float-left" >&nbsp;to :&nbsp;</span>
          <span class="float-left"><datepicker v-model="endDate" /></span>
          <span><va-button class="ml-2" @click="getInfoCount">Search</va-button></span>
        </div>
        <div class="flex xs12 md4 text--right">
          <span>
            <va-button @click="downloadStatistic" color="success">
              <span>Download</span>
              <va-icon class="ml-2" name="download"></va-icon>
            </va-button>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="flex xs12 md2">
          <va-inner-loading :loading="isLoading">
            <va-card color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{
                    this.registerMemberCount
                      ? this.registerMemberCount.count
                      : 0
                  }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "registerMember") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
        <div class="flex xs12 md2">
          <va-inner-loading :loading="isLoading">
            <va-card class="mb-4" color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{
                    this.uniqueUserCreateFormCount
                      ? this.uniqueUserCreateFormCount.count
                      : 0
                  }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "uniqueUser") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
        <div class="flex xs12 md2">
          <va-inner-loading :loading="isLoading">
            <va-card class="mb-4" color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{ this.totalFormCount ? this.totalFormCount.count : 0 }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "totalForm") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
        <div class="flex xs12 md2">
          <va-inner-loading :loading="isLoading">
            <va-card class="mb-4" color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{ this.formFilledCount ? this.formFilledCount.count : 0 }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "formFilled") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
        <div class="flex xs12 md2">
          <va-inner-loading :loading="isLoading">
            <va-card class="mb-4" color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{ this.respondedCount ? this.respondedCount.count : 0 }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "responded") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
        <div class="flex xs12 md2">
          <va-inner-loading :loading="isLoading">
            <va-card class="mb-4" color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{
                    this.weeklyPaidUserCount
                      ? this.weeklyPaidUserCount.count
                      : 0
                  }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "paidUser") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
      </div>
      <div class="row align--center mt-3">
        <div class="flex md12">
          <h5 class="display-5">Status from beginning</h5>
        </div>
      </div>
      <div class="row">
        <div class="flex xs12 md3">
          <va-inner-loading :loading="isLoading">
            <va-card class="mb-4" color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{ this.paidUserCount ? this.paidUserCount.count : 0 }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "paidUser") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
        <div class="flex xs12 md3">
          <va-inner-loading :loading="isLoading">
            <va-card class="mb-4" color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{ this.totalUserCount ? this.totalUserCount.count : 0 }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "totalUser") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
        <div class="flex xs12 sm3">
          <va-inner-loading :loading="isLoading">
            <va-card class="mb-4" color="info">
              <va-card-content class="card-count">
                <p class="display-2 mb-0" style="color: white;">
                  {{ this.expiredMemberCount ? this.expiredMemberCount.count : 0 }}
                </p>
                <p style="color: white;">
                  {{ $t("dashboard.info." + "expiredMember") }}
                </p>
              </va-card-content>
            </va-card>
          </va-inner-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useGlobalConfig } from "vuestic-ui";
import { mapActions, mapGetters } from "vuex";
import Datepicker from "vue3-datepicker";
import { ref } from "vue";
import { format, subDays, startOfDay, endOfDay } from "date-fns";

const ExcelJS = require("exceljs");

export default {
  name: "DashboardInfoBlock",
  components: {
    Datepicker
  },
  data() {
    return {
      startDate: ref(subDays(startOfDay(new Date()), 7)),
      endDate: ref(endOfDay(new Date())),
      isLoading: false
    };
  },
  async mounted() {
    await this.getInfoCount();
    await this.getPaidUserCount();
    await this.getTotalUserCount();
    await this.getExpiredMemberCount();
  },
  methods: {
    ...mapActions([
      "getRegisterMemberCount",
      "getUniqueUserCreateFormCount",
      "getTotalFormCount",
      "getPaidUserCount",
      "getFormFilledCount",
      "getRespondedCount",
      "getTotalUserCount",
      "getExpiredMemberCount",
      "getWeeklyPaidUserCount"
    ]),

    async getInfoCount() {
      try {
        this.isLoading = true;
        await this.getRegisterMemberCount({
          startDate: format(this.startDate, "yyyy-MM-dd"),
          endDate: format(this.endDate, "yyyy-MM-dd")
        });
        await this.getUniqueUserCreateFormCount({
          startDate: format(this.startDate, "yyyy-MM-dd"),
          endDate: format(this.endDate, "yyyy-MM-dd")
        });
        await this.getTotalFormCount({
          startDate: format(this.startDate, "yyyy-MM-dd"),
          endDate: format(this.endDate, "yyyy-MM-dd")
        });
        await this.getFormFilledCount({
          startDate: format(this.startDate, "yyyy-MM-dd"),
          endDate: format(this.endDate, "yyyy-MM-dd")
        });
        await this.getRespondedCount({
          startDate: format(this.startDate, "yyyy-MM-dd"),
          endDate: format(this.endDate, "yyyy-MM-dd")
        });
        await this.getWeeklyPaidUserCount({
          startDate: format(this.startDate, "yyyy-MM-dd"),
          endDate: format(this.endDate, "yyyy-MM-dd")
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async downloadStatistic() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Survein";
      workbook.lastModifiedBy = "Survein";
      workbook.created = new Date();

      const worksheet = workbook.addWorksheet("Survein-report");

      const header = worksheet.addRow(["", `Statistic from ${format(this.startDate, "dd-MM-yyyy")} to ${format(this.endDate, "dd-MM-yyyy")}`, "Count"]);
      header.eachCell(cell => {
        cell.font = {
          bold: true
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFEFEFEF" }
        };
      });

      worksheet.addRow([1, "Register Member", this.registerMemberCount.count]);

      worksheet.addRow([
        2,
        "Unique User Who Create Form",
        this.uniqueUserCreateFormCount.count
      ]);

      worksheet.addRow([3, "Total Form Creation", this.totalFormCount.count]);

      worksheet.addRow([4, "Form Filled", this.formFilledCount.count]);

      worksheet.addRow([5, "Responded", this.respondedCount.count]);

      worksheet.addRow([6, "Weekly Paid License", this.weeklyPaidUserCount.count]);

      const header2 = worksheet.addRow(["", "Status from beginning", "Count"]);
      header2.eachCell(cell => {
        cell.font = {
          bold: true
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFEFEFEF" }
        };
      });

      worksheet.addRow([1, "Paid License", this.paidUserCount.count]);
      worksheet.addRow([2, "Total User", this.totalUserCount.count]);
      worksheet.addRow([3, "Expired License", this.expiredMemberCount.count]);

      worksheet.getColumn(1).width = 5;
      worksheet.getColumn(2).width = 40;
      worksheet.getColumn(3).width = 15;
      worksheet.getColumn(4).width = 15;

      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadBlob(buffer, "survein-report.xlsx", "application/octet-stream");
    },

    downloadURL(data, fileName) {
      const a = document.createElement("a");
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.style.display = "none";
      a.click();
      a.remove();
    },

    downloadBlob(data, fileName, mimeType) {
      const blob = new Blob([data], {
        type: mimeType
      });
      const url = window.URL.createObjectURL(blob);
      this.downloadURL(url, fileName);
      setTimeout(() => window.URL.revokeObjectURL(url), 1000);
    }
  },
  computed: {
    ...mapGetters([
      "registerMemberCount",
      "uniqueUserCreateFormCount",
      "totalFormCount",
      "paidUserCount",
      "formFilledCount",
      "respondedCount",
      "totalUserCount",
      "expiredMemberCount",
      "weeklyPaidUserCount"
    ]),

    formatStartDate() {
      let date = null;
      date = format(this.startDate, "yyyy-MM-dd");
      return date;
    },

    theme() {
      return useGlobalConfig().getGlobalConfig().colors || {};
    }
  }
};
</script>

<style lang="scss" scoped>
.row-separated {
  .flex + .flex {
    border-left: 1px solid var(--va-background);
  }
}

.rich-theme-card-text {
  line-height: 24px;
}

.card-count{
  min-height: 115px;
}

.float-left{
  float: left;
}

.date-wrapper{
  line-height: 40px;
}
</style>
